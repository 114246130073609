import { FC, useCallback, useMemo, useRef } from 'react'
import {
  OccurrenceTravel,
  OccurrenceTravelIconStatus,
  occurrenceTravelKeys,
  TravelStatusIcon,
} from 'services/occurrence/types'

import { useToggle } from 'shared/hooks'
import { Tooltip, Icon as IconComponent } from 'components'

import styles from './OccurrenceTravelIcon.module.scss'
import { handleOccurrenceStateLabel } from 'domains/occurrence/components/OccurrenceTravelIcon/utils'

export interface OccurrenceTravelIconProps {
  travelStatus: OccurrenceTravel
  alert: boolean
  checklistFailure: boolean
  selected?: boolean
  completed?: boolean
}

const Icon: FC<{
  iconType: string
  status: OccurrenceTravel
  selected: boolean
}> = ({ iconType, status, selected }) => {
  const tooltip = useToggle()
  const iconRef = useRef<HTMLDivElement>(null)
  const tooltipTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const delay = 300

  const travelStatusIcon: TravelStatusIcon = {
    TRAVEL_WAITING: <></>,
    TRAVEL_SENT: (
      <IconComponent name="message" aria-label="ícone-de-mensagem" />
    ),
    TRAVEL_VIEWED: (
      <IconComponent name="vehicle-car" aria-label="ícone-de-carro" />
    ),
    TRAVEL_STARTED: (
      <IconComponent name="location" aria-label="ícone-de-localização" />
    ),
    TRAVEL_FINISHED: (
      <IconComponent name="occurrence-closed" aria-label="ícone-de-escudo" />
    ),
    FINISHED: (
      <IconComponent name="occurrence-closed" aria-label="ícone-de-escudo" />
    ),
  }

  const handleMouseEnter = useCallback(() => {
    tooltipTimeout.current = setTimeout(() => {
      tooltip.show()
    }, delay)
  }, [])

  const handleMouseLeave = useCallback(() => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current)
    }
    tooltip.hide()
  }, [])

  return (
    <div
      ref={iconRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={[
        styles.icon,
        styles[status],
        styles[iconType],
        selected && styles.selected,
      ].join(' ')}
    >
      {travelStatusIcon[status]}

      <Tooltip
        parentRef={iconRef}
        type="informative"
        isVisible={tooltip.isVisible}
      >
        {handleOccurrenceStateLabel(status, iconType === 'SUCCESS')}
      </Tooltip>
    </div>
  )
}

export const OccurrenceTravelIcon = ({
  travelStatus,
  alert,
  checklistFailure,
  selected = false,
  completed = false,
}: OccurrenceTravelIconProps) => {
  const validStatus = useMemo(() => {
    return Object.values(occurrenceTravelKeys).filter(
      (status) => status !== 'TRAVEL_WAITING',
    )
  }, [])

  const currentStatusIndex = validStatus.indexOf(travelStatus)

  const determineIconStatus = (
    status: OccurrenceTravel,
    index: number,
  ): OccurrenceTravelIconStatus => {
    let iconStatus: OccurrenceTravelIconStatus = 'DEFAULT'

    if (status === travelStatus) {
      iconStatus = alert ? 'ALERT' : 'DEFAULT'
    } else if (index < currentStatusIndex) {
      iconStatus = 'SUCCESS'
    }

    if (status === 'TRAVEL_FINISHED') {
      if (completed) {
        iconStatus = checklistFailure ? 'CHECKLIST_FAILURE' : 'SUCCESS'
      } else {
        iconStatus = alert ? 'ALERT' : 'DEFAULT'
      }
    }

    return iconStatus
  }

  return (
    <div className={styles.container}>
      {validStatus.map((status, index) => {
        if (index > currentStatusIndex) return null

        const iconType = determineIconStatus(status as OccurrenceTravel, index)

        return (
          <Icon
            key={index}
            iconType={iconType}
            status={status as OccurrenceTravel}
            selected={selected}
          />
        )
      })}
    </div>
  )
}
