import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'

import { useGetAllOccurrencesByCoverage } from 'domains/occurrence/hooks/services/occurrence/useGetAllOccurrencesByCoverage'
import usePostAndHandleIntervention from 'domains/occurrence/hooks/services/occurrence/usePostAndHandleIntervention'
import { getBorderStyle } from 'domains/occurrence/utilities/occurrenceCard/occurrenceCard'
import { OccurrenceHistory } from 'domains/occurrence/screens/OccurrenceManagement/types'
import { getInterventionTitle } from 'domains/occurrence/utilities/intervention'
import { InterventionTag } from 'domains/occurrence/data/interventionTag'
import { UserInfoContext, useToast, useToggle, useUserInfo } from 'shared/hooks'
import { handleChangeState, handleModalOptions } from '../../utils'
import { useDisableHabitDeviation } from 'services/attendance'
import {
  ChangeTactical,
  FormProps,
} from 'domains/occurrence/components/ChangeTactical/ChangeTactical'
import {
  AttendanceCompletionModal,
  InfoModal,
  InterventionJustification,
} from 'domains/occurrence/components'
import {
  useChangeState,
  useFinishOccurrence,
  usePostAndHandleAttendance,
} from 'domains/occurrence/hooks'
import {
  InterventionPayload,
  InterventionStateEnum,
  InterventionTypeEnum,
} from 'services/occurrence/types'
import {
  InterventionAction,
  InterventionNoteSubmitData,
  OccurrenceActionValue,
} from 'domains/occurrence/types'
import { Icon, IconButton } from 'components'
import { buildPath, paths } from 'routes'

type ChangeStateKebabProps = {
  isUserInAttendance: boolean
  occurrence: OccurrenceHistory
  canDisableHabitDeviation: boolean
  onClose: () => void
}

const ChangeStateKebab: React.FC<ChangeStateKebabProps> = ({
  occurrence,
  isUserInAttendance,
  canDisableHabitDeviation,
  onClose,
}) => {
  const [visible, setVisible] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState(false)
  const [interventionJustification, setInterventionJustification] = useState<{
    state: InterventionStateEnum
    tag: InterventionTag
  } | null>(null)

  const vehicleTravelEnabled = useMemo(
    () => !!occurrence?.parameters?.vehicleTravelEnabled,
    [occurrence], // eslint-disable-line
  )

  const finishAttendanceModal = useToggle()
  const changeTactical = useToggle()
  const { addToast } = useToast()
  const infoModal = useToggle()
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const { mutate: finishOccurrence } = useFinishOccurrence(occurrence.id, {})
  const { mutate: mutateCreateAttendance } = usePostAndHandleAttendance()
  const { mutate: changeState } = useChangeState(occurrence.id, {})
  const { mutateCreateIntervention } = usePostAndHandleIntervention(
    occurrence.id,
  )
  const { data: occurrences } = useGetAllOccurrencesByCoverage()
  const { mutate: disableHabitDeviation, isPending: disabledHabitIsLoading } =
    useDisableHabitDeviation()

  const userContextInfo = useContext(UserInfoContext)

  const handleView = useCallback((): void => {
    navigate(
      buildPath({
        path: paths.occurrence.attendance,
        params: { occurrenceId: occurrence.id },
      }),
      {
        state: {
          readonly: true,
          from: 'management-occurrence',
        },
      },
    )
  }, [history, occurrence.id])

  const handleViewMap = useCallback(() => {
    navigate(paths.occurrence.map, {
      state: {
        occurrenceId: occurrence.id,
      },
    })
  }, [history, occurrence.id])

  const handleAttend = useCallback(() => {
    if (!userContextInfo.userInfo) return
    if (!isUserInAttendance) {
      mutateCreateAttendance(
        {
          occurrenceId: occurrence.id,
          userId: userContextInfo.userInfo?.id || '',
        },
        {
          onSuccess: (data) => {
            navigate(
              buildPath({
                path: paths.occurrence.attendance,
                params: { occurrenceId: data.id },
              }),
              {
                state: {
                  shouldRedirectToOccurrenceManagement: true,
                },
              },
            )
          },
          onError: () => {
            addToast({
              message: 'Erro ao criar atendimento.',
              error: true,
              type: 'alert',
            })
          },
        },
      )
    } else {
      infoModal.show()
    }
  }, [
    addToast,
    history,
    infoModal,
    isUserInAttendance,
    mutateCreateAttendance,
    occurrence.id,
    userContextInfo.userInfo,
  ])

  const handleContinueAttendance = useCallback(() => {
    navigate(
      buildPath({
        path: paths.occurrence.attendance,
        params: { occurrenceId: occurrence.id },
      }),
    )
  }, [history, occurrence.id])

  const handleCreateIntervention = useCallback(
    (data: InterventionAction) => {
      const { interventionTag, ...rest } = data
      const tags = data.tags || []
      tags.push({
        name: data.interventionTag,
      })

      const intervention: InterventionPayload = {
        userId: userInfo.id,
        title: getInterventionTitle(data, interventionTag),
        tags,
        ...rest,
      }

      changeState(intervention)
      onClose()
    },
    [changeState, onClose],
  )

  const handleFinishOccurrence = useCallback(
    (data: InterventionNoteSubmitData) => {
      finishAttendanceModal.hide()

      const dataFormatted = {
        typeName: InterventionTypeEnum.STATE_CHANGED,
        attributes: {
          stateName: InterventionStateEnum.FINISHED,
        },
        interventionTag: InterventionTag.FINALIZADA,
        note: data.note,
      }

      const { interventionTag, ...rest } = dataFormatted

      const tags = data.interventionNoteTags || []
      tags.push({
        name: data.interventionTag,
      })

      const intervention: InterventionPayload = {
        userId: userInfo.id,
        title: getInterventionTitle(dataFormatted, interventionTag),
        tags: tags.filter((tags) => tags.name),
        ...rest,
      }

      finishOccurrence(intervention)
      onClose()
    },
    [finishAttendanceModal, finishOccurrence, onClose],
  )

  const onSubmitFinishOccurrence = useCallback(
    async (data: InterventionNoteSubmitData) => {
      if (data.postponeUntil) {
        const partitions = occurrence?.partitions?.filter(
          (partition) => partition.isInOccurrence,
        )

        if (partitions) {
          const date = new Date(data.date as number)
          const [hour, minutes] =
            (data.hour && data.hour.split(':').map(Number)) || []

          date.setHours(hour)
          date.setMinutes(minutes)

          await Promise.all(
            partitions?.map((partition) => {
              disableHabitDeviation({
                partitionId: partition.id,
                scheduledDate: date.getTime(),
              })
            }),
          )
            .then(() => handleFinishOccurrence(data))
            .catch(() => {
              addToast({
                message: 'Erro ao adiar atendimento',
                type: 'alert',
              })
            })
        }
      } else
        handleFinishOccurrence({
          note: data.note,
          interventionNoteTags: data.interventionNoteTags,
          interventionTag: data.interventionTag,
        })
    },
    [addToast, disableHabitDeviation, handleFinishOccurrence, occurrence], // eslint-disable-line
  )

  const subActions = [
    {
      label: 'Solicitação visualizada',
      handler: () => {
        setInterventionJustification({
          state: InterventionStateEnum.TRAVEL_VIEWED,
          tag: InterventionTag.TRAVEL_VIEWED,
        })
      },
    },
    {
      label: 'Trânsito iniciado',
      handler: () => {
        setInterventionJustification({
          state: InterventionStateEnum.TRAVEL_STARTED,
          tag: InterventionTag.TRAVEL_STARTED,
        })
      },
    },
    {
      label: 'Chegada no local',
      handler: () => {
        setInterventionJustification({
          state: InterventionStateEnum.TRAVEL_FINISHED,
          tag: InterventionTag.TRAVEL_FINISHED,
        })
      },
    },
  ]

  const handleTactical = useCallback(
    (data: FormProps) => {
      changeTactical.hide()
      mutateCreateIntervention(
        {
          title: `Solicitação enviada ao agente tático ${data.tactical.name}`,
          typeName: 'STATE_CHANGE',
          note: data.comment,
          userId: userInfo.id,
          tags: [
            {
              name: 'deslocamentoEnviado',
            },
          ],
          attributes: {
            stateName: 'TRAVEL_SENT',
            agent: {
              id: data.tactical.id,
              name: data.tactical.name,
            },
            vehicle: {
              id: data.tactical.vehicleId,
              licensePlate: data.tactical.licensePlate,
            },
            agentCoordinates: data.tactical.coordinates,
          },
        },
        {
          onError: () => {
            addToast({
              message: 'Erro ao realizar intervenção',
              type: 'alert',
            })
          },
        },
      )
      onClose()
    },
    [addToast, changeTactical, mutateCreateIntervention, onClose],
  )

  const handleInterventionJustification = useCallback(
    (note: string) => {
      if (interventionJustification) {
        const payload = handleChangeState(
          note,
          interventionJustification.state,
          occurrence,
        )

        handleCreateIntervention(payload)
        setInterventionJustification(null)
      }
    },
    [handleCreateIntervention, interventionJustification, occurrence],
  )

  const columnName = {
    AVAILABLE: 'Disponível',
    IN_PROGRESS: 'Em atendimento',
    QUEUED: 'Em observação',
  }

  return (
    <div className={styles.container}>
      {changeTactical.isVisible && occurrences && (
        <ChangeTactical
          title={
            occurrence.stateName === 'TRAVEL_WAITING'
              ? 'Atribuir agente tático'
              : 'Alterar agente tático'
          }
          isVisible
          onClose={changeTactical.hide}
          occurrenceList={occurrences.data}
          onContinue={handleTactical}
        />
      )}
      {infoModal.isVisible && (
        <InfoModal
          isVisible
          onClose={infoModal.hide}
          title="Ocorrência em atendimento"
          text="Você está atualmente atendendo a uma ocorrência. Antes de prosseguir com outra, por favor, conclua a atual."
        />
      )}
      {!!interventionJustification && (
        <InterventionJustification
          isVisible
          onClose={() => setInterventionJustification(null)}
          onContinue={handleInterventionJustification}
        />
      )}
      {finishAttendanceModal.isVisible && (
        <AttendanceCompletionModal
          isVisible
          onClose={finishAttendanceModal.hide}
          onSave={onSubmitFinishOccurrence}
          isDisabledSave={disabledHabitIsLoading}
          canDisableHabitDeviation={canDisableHabitDeviation}
          occurrenceTypeId={occurrence.type.id}
          serviceTypeId={occurrence.account.serviceType.id}
        />
      )}

      <IconButton onClick={() => setVisible(true)}>
        <Icon name="menu-kebab" color="element-default" />
      </IconButton>
      {visible && (
        <ul onMouseLeave={() => setVisible(false)}>
          {occurrence &&
            handleModalOptions(
              occurrence.stateName,
              occurrence.operator?.id === userInfo.id,
            )?.map((item) => {
              const isSubAction = item.displacementStep === 'SUB_ACTIONS'
              const borderStyle = getBorderStyle(
                columnName[occurrence.stateName] || 'Deslocamento',
                item.label as OccurrenceActionValue,
              )
              const isDisabled =
                item.displacementStep === 'TRAVEL_WAITING' &&
                !vehicleTravelEnabled

              return (
                <li
                  key={item.displacementStep}
                  className={[
                    styles[borderStyle],
                    isDisabled && styles.isDisable,
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  onClick={() => {
                    if (!isDisabled) {
                      const handlers = {
                        AVAILABLE: () =>
                          setInterventionJustification({
                            state: InterventionStateEnum.AVAILABLE,
                            tag: InterventionTag.AVAILABLE,
                          }),
                        VIEW: handleView,
                        VIEW_ON_MAP: handleViewMap,
                        ATTENDANCE:
                          occurrence.stateName === 'IN_PROGRESS'
                            ? handleContinueAttendance
                            : handleAttend,
                        QUEUED: () => {
                          setInterventionJustification({
                            state: InterventionStateEnum.QUEUED,
                            tag: InterventionTag.QUEUED,
                          })
                        },
                        FINISHED: () => {
                          finishAttendanceModal.show()
                        },
                        TRAVEL_WAITING: () => {
                          setInterventionJustification({
                            state: InterventionStateEnum.TRAVEL_WAITING,
                            tag: InterventionTag.TRAVEL_WAITING,
                          })
                        },
                        SET_TACTICAL: () => {
                          changeTactical.show()
                        },
                        CHANGE_TACTICAL: () => {
                          changeTactical.show()
                        },
                        TRAVEL_VIEWED: () => {
                          setInterventionJustification({
                            state: InterventionStateEnum.TRAVEL_VIEWED,
                            tag: InterventionTag.TRAVEL_VIEWED,
                          })
                        },
                        TRAVEL_STARTED: () => {
                          setInterventionJustification({
                            state: InterventionStateEnum.TRAVEL_STARTED,
                            tag: InterventionTag.TRAVEL_STARTED,
                          })
                        },
                        TRAVEL_FINISHED: () => {
                          setInterventionJustification({
                            state: InterventionStateEnum.TRAVEL_FINISHED,
                            tag: InterventionTag.TRAVEL_FINISHED,
                          })
                        },
                      }
                      handlers[item.displacementStep]()

                      setVisible(false)
                    }
                  }}
                  onMouseLeave={() => isSubAction && setActiveSubMenu(false)}
                  onMouseEnter={() => isSubAction && setActiveSubMenu(true)}
                >
                  {item.label}
                  {isSubAction && <Arrow className={styles.flexArrow} />}
                  {activeSubMenu && isSubAction ? (
                    <div className={styles.subMenu}>
                      {subActions.map(
                        ({ label: subLabel, handler: subHandler }) => (
                          <button
                            key={subLabel}
                            className={styles.dropListSubItem}
                            onClick={(e) => {
                              subHandler?.(occurrence.id)
                              e.stopPropagation()
                            }}
                          >
                            {subLabel}
                          </button>
                        ),
                      )}
                    </div>
                  ) : null}
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}

export default ChangeStateKebab
